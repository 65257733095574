import { useEffect, useState } from 'react';

import Image from 'next/image';
import dynamic from 'next/dynamic';

import {
  Footer,
  Row,
  Col,
  Button,
  Paragraph,
  StoreButton,
  Container,
} from 'darrius';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../utils';
import { getFlashTitle } from '../../utils/getFlashTitle';

import { HeadContent, Header, Toast } from '../../components';

import styles from './RequestTemplate.module.scss';

import { type IRequestTemplateParams } from './@interfaces';
import { type IUserCliDataResponse } from '../../modules/user';
import { type TRequestInfo } from '../../modules/postRequest/pageProps/@interfaces';
import { Alert } from '../Chat/Alert/Alert';

interface IRequestTemplateProps extends IRequestTemplateParams {
  userData: IUserCliDataResponse;
}

const ServiceExpressDialog = dynamic(
  () => import('./ServiceExpressDialog/ServiceExpressDialog'),
  {
    ssr: false,
  }
);

const DOWNLOAD_APP_LINK =
  'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=pos-request&utm_medium=getninjas&utm_campaign=cli_teste_ab_CLA_1001&utm_content=b';

const MY_ORDERS_LINK = 'https://www.getninjas.com.br/meus-pedidos';

const SERVICES = [
  {
    name: 'Assistência Técnica',
    slug: 'reformas-e-reparos/assistencia-tecnica',
  },
  { name: 'Diarista', slug: 'familia/diarista' },
  { name: 'Professor', slug: 'aulas' },
];

const FraudAlert = () => {
  return (
    <Col className="col-sm-12">
      <Alert.Root onClick={() => {}} direction="column">
        <Alert.Header>
          <Alert.Icon
            image={imgPrefix('/images/post-request/error.svg')}
            description="Ícone de aviso"
          />
          <Alert.Title>
            Não caia em golpes. Confira abaixo, algumas dicas para se prevenir
          </Alert.Title>
        </Alert.Header>
        <Alert.Body className={styles['alert-body']}>
          <Alert.Text>
            O prestador de serviço diz que você deve pagar peças ou custos
            extras a um entregador. Prefira pagar por transferências PIX.{' '}
            <Alert.Strong className="alert-text-strong">
              Evite usar cartão de crédito.
            </Alert.Strong>
          </Alert.Text>
          <br />
          <Alert.Text>
            Se for pagar o serviço por cartão de crédito avalie se o visor da
            maquininha não possui avarias que dificultam o verificação do valor.{' '}
            <Alert.Strong className="alert-text-strong">
              Recuse se não conseguir visualizar.
            </Alert.Strong>
          </Alert.Text>
          <br />
          <Alert.Text>
            Desconfie se receber orçamentos muito baratos.
          </Alert.Text>
        </Alert.Body>
      </Alert.Root>
      <Row className={styles['post-request-hero__button-desktop']}>
        <Button.Link href="https://www.getninjas.com.br/seguranca" fullWidth>
          Acesse mais dicas
        </Button.Link>
      </Row>
    </Col>
  );
};

export const RequestTemplate = (props: IRequestTemplateProps) => {
  const {
    footerContent,
    meta,
    slug = '',
    hero,
    relatedCategories = null,
    request = {},
    userData,
  } = props;

  const [isMobile, setIsMobile] = useState(false);

  const { isServiceExpress = false } = request as TRequestInfo;

  const flashTitle = getFlashTitle(userData.notificationCount || 0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 991);
      };

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('chat__nav-message', {
        userId: userData?.id,
      });
    }, 5000);
    return () => clearTimeout(trackingTimeout);
  }, [userData]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    const eventCategory = slug.split('/')[0];
    const eventSubCategory = slug.split('/')[1];
    window.dataLayer.push({
      event: 'request_created',
      category: eventCategory,
      sub_category: eventSubCategory,
    });
  }, [slug]);

  return (
    <>
      <div data-category-slug={slug}></div>

      <HeadContent
        flashTitle={flashTitle}
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType={meta.pageType}
        keywords={meta.keywords}
        noindex
      />

      <Header.Auth userData={userData} />

      <Container className={styles.container}>
        <div className={`${styles.section}`}>
          <Paragraph
            title="Pedido enviado"
            headline
            size="1"
            className={`${styles.title} no-margin`}
          />

          <Paragraph
            text={
              isMobile
                ? 'Acesse o site ou o aplicativo<br> para acompanhar o seu pedido.'
                : 'Você receberá o contato dos<br> profissionais via Whatsapp'
            }
            headline
            className={`${styles.subtitle} no-margin`}
          />

          <Row
            className={`${styles['post-request-hero__button']} ${styles['post-request-hero__fraud']} hide-sm post-request-hero__button`}
          >
            <FraudAlert />
          </Row>

          <Row
            className={`${styles['post-request-hero__button']} center-sm hide-md post-request-hero__button`}
          >
            <FraudAlert />
          </Row>
        </div>

        <div
          className={`${styles['post-request-hero__comum-button']} post-request-hero__comum-button`}
        >
          <div className={`hide-sm ${styles['qrcode-area']}`}>
            <Paragraph
              className={styles['post-request-hero__button-desktop']}
              text="Baixe o aplicativo e acompanhe seus <br>pedidos direto do seu celular."
              headline
            />
            <div className={styles['qr-code-text']}>{hero.qrcode.text}</div>

            <Image
              src={imgPrefix('/images/ab-test/post-request/b.svg')}
              alt={hero.qrcode.image.alt}
              title={hero.qrcode.image.alt}
              width={195}
              height={195}
              placeholder="blur"
              blurDataURL={getBlurDataURL()}
              priority
            />

            <Row>
              <Col className="col-sm-6">
                <StoreButton store="playstore" size="small" />
              </Col>
              <Col className="col-sm-6">
                <StoreButton store="appstore" size="small" />
              </Col>
            </Row>
          </div>
          <div className={styles.status}>
            <Paragraph
              title="Como está o seu pedido:"
              size="3"
              className={styles['hows-your-request']}
            />

            <div>
              {hero.items.map(({ text, image }, index) => (
                <Row key={text} className={`start-sm top-sm ${styles.step}`}>
                  <Image
                    src={imgPrefix(image.replace('images', 'images/ab-test'))}
                    alt={text}
                    width={24}
                    height={index == hero.items.length - 1 ? 24 : 60}
                  />

                  <span>{text}</span>
                </Row>
              ))}
            </div>
          </div>

          <Paragraph
            text='Acesse a página"Meus pedidos” ou baixe o aplicativo para acompanhar a solicitação'
            className={`hide-md ${styles['post-request-hero-text-mobile']}`}
          />

          <Row className={styles['post-request-hero__button-desktop']}>
            <Col className="col-sm-12">
              <Button.Link
                href={MY_ORDERS_LINK}
                fullWidth
                variation={'secondary'}
              >
                Acessar &quot;Meus pedidos&quot;
              </Button.Link>
            </Col>
          </Row>
          <Row
            className={`${styles['post-request-hero__button']} hide-md post-request-hero__button`}
          >
            <Col className="col-sm-12">
              <Button.Link href={DOWNLOAD_APP_LINK} fullWidth variation="clear">
                Baixar o Aplicativo
              </Button.Link>
            </Col>
          </Row>
        </div>
      </Container>

      {relatedCategories && (
        <div>
          <Paragraph
            title="Também pode te interessar"
            size="2"
            className={`${styles['might-interest']} no-margin`}
            headline
          />

          <Row
            className={`center-sm row--slider-sm ${styles['most-requested-services']}`}
          >
            {SERVICES.map((service, i) => (
              <a
                href={`/${service.slug}`}
                key={service.name}
                className={styles.service}
              >
                <Image
                  src={imgPrefix(`/images/ab-test/post-request/${i}.jpg`)}
                  alt={service.name}
                  width={250}
                  height={250}
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                  quality={100}
                  style={{
                    objectFit: 'contain',
                  }}
                />

                <div className={styles.service__details}>
                  <span className={styles.service__name}>{service.name}</span>

                  <span className={styles.service__hire}>Contrate agora</span>
                </div>
              </a>
            ))}
          </Row>
        </div>
      )}

      {isServiceExpress && (
        <>
          <ServiceExpressDialog request={request as TRequestInfo} />
          <Toast />
        </>
      )}

      <Footer data={footerContent} />
    </>
  );
};
